$(document).ready(function () {
  var urlPath = window.location.pathname;
  var loginPath = '/' + window.lang + '/';
  var registerPath = '/' + window.lang + '/register.html';

  $('.js-menu-item:has( > ul)').addClass('menu-dropdown-icon');
  $('.js-menu-item > ul > li:has(ul)').addClass('multi-level-menu');

  $('.js-menu-item').each(function() {
    var lineHeight = parseInt($(this).children().css('line-height'));
    var paddingTop = parseInt($(this).children().css('padding-top'));
    var paddingBottom = parseInt($(this).children().css('padding-bottom'));
    var menuItemLineHeight = lineHeight + paddingTop + paddingBottom;

    var menuItemHeight = $(this).outerHeight();

    if (menuItemHeight > menuItemLineHeight) {
      $(this).addClass('secondline');
    }
  });

  $('.js-menu-item').hover(function (e) {
    if (window.onDesktopScreen()) {
      $(this).addClass('active');
      $(this).children('ul').stop(true, true).slideDown(250);
    }
  }, function() {
    if (window.onDesktopScreen()) {
      $(this).removeClass('active');
      $(this).children('ul').stop(true, true).slideUp(0);
    }
  });

  $('.js-menu-item-mobile').click(function (e) {
    if (!window.onDesktopScreen()) {
      e.preventDefault();
      $(this).next().slideToggle(250);
    }
  });

  $('.js-hamburger').click(function (e) {
    $('.icon-bar').toggleClass('change');
    $('.js-main-menu').toggleClass('active');
    $('.js-menu').toggleClass('open');
    $('.js-modal-menu-overlay').toggleClass('active');
    $('.js-user-overlay').removeClass('active');

    checkBodyScrollabel('.js-modal-menu-overlay');

    e.preventDefault();
  });

  function checkBodyScrollabel(elm) {
    if (!$(elm).hasClass('active')) {
      $('html, body').removeClass('no-scroll');
    } else {
      $('html, body').addClass('no-scroll');
    }
  }

  $('.js-modal-menu-overlay').click(function(event) {
    if (event.target !== this) {
      return;
    }

    $(this).removeClass('active');
    $('.icon-bar').removeClass('change');

    checkBodyScrollabel('.js-modal-menu-overlay');
  });

  $('.js-user-info-box').click(function(e) {

    if (window.onDesktopScreen()) {
      return;
    }

    $('.icon-bar').removeClass('change');
    $('.js-main-menu').removeClass('active');
    $('.js-menu').removeClass('open');
    $('.js-modal-menu-overlay').removeClass('active');
    $('.js-overlay').toggleClass('active');
    $('.js-user-overlay').toggleClass('active');

    checkBodyScrollabel('.js-user-overlay');
  });

  $('.js-user-info-box').hover(function(e) {
    if (window.onDesktopScreen()) {
      $(this).addClass('dropdown-active');
      $(this).find('.js-mouse-out-handle').stop(true, true).slideDown(250);
    }
  }, function() {
    if (window.onDesktopScreen()) {
      $(this).removeClass('dropdown-active');
      $(this).find('.js-mouse-out-handle').stop(true, true).slideUp(0);
    }
  });

  function manageItemHeader() {

    if ((window.onTabletScreen() || window.onMobileScreen())) {
      $('.js-hamburger').removeClass('hidden').addClass('show');

      $('.js-language-box').removeClass('show').addClass('hidden');
    } else {
      $('.js-language-box').removeClass('hidden').addClass('show');
    }

    if (urlPath === loginPath) {
      $('.js-hamburger').removeClass('show').addClass('hidden');
    }

    if (urlPath === registerPath) {
      $('.js-language-box').removeClass('hidden').addClass('show');

      $('.js-hamburger').removeClass('show').addClass('hidden');
    }
  }

  $(window).on('resize', function() {
    manageItemHeader();
  });

  manageItemHeader();
});
